import { AuthApi, makeGetUrl } from '@/libraries/api'
// import ItemSchema from './schema'

function getItems(page = 1, limit = 10, query = '') {
  const url = `/app_admin/users`
  const params = {
    page,
    limit,
    query
  }
  return AuthApi().get(makeGetUrl(url, params))
}

function getItem(idx) {
  const url = `/app_admin/users/${idx}`
  return AuthApi().get(url)
}

function getTokens(userIdx = -1, page = 1, limit = 10) {
  const url = `/app_admin/users/${userIdx}/tokens`
  const dt = {
    page,
    limit
  }
  return AuthApi().get(makeGetUrl(url, dt))
}

function deleteToken(userIdx = -1, tokenIdx) {
  const url = `/app_admin/users/${userIdx}/tokens/${tokenIdx}`
  return AuthApi().delete(url)
}

function getAccessLogs(userIdx = -1, page = 1, limit = 10) {
  const url = `/app_admin/users/${userIdx}/access_logs`
  const dt = {
    page,
    limit
  }
  return AuthApi().get(makeGetUrl(url, dt))
}

function addItem(user = {}) {
  const url = `/app_admin/users`
  const dt = {
    user
  }
  return AuthApi().post(url, dt)
}

function updateItem(userIdx = -1, data = {}) {
  const url = `/app_admin/users/${userIdx}`
  const dt = {}
  if (data.user_extra.privileges) {
    dt.user_privileges = data.user_extra.privileges
  }
  if (data.user_extra.extra) {
    dt.user_extra = data.user_extra.extra
  }

  return AuthApi().put(url, dt)
}

function changeUserPasswordByAdmin(userIdx = -1, userPassword = '') {
  const url = `/app_admin/users/${userIdx}/password`
  const dt = {
    user_password: userPassword
  }

  return AuthApi().put(url, dt)
}

// function deleteItem(idx) {
//   const url = `/apps/${idx}`
//   return AuthApi().delete(url)
// }

export default {
  getItems,
  getItem,
  getTokens,
  deleteToken,
  getAccessLogs,
  addItem,
  updateItem,
  changeUserPasswordByAdmin
  // addItem,
  // updateItem,
  // deleteItem
}
