<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        lg="12"
        sm="12"
        xs="12"
      >
        <v-card
          class="mx-auto rounded"
        >
          <v-card-text>
            <v-simple-table
              :dense="false"
              :fixed-header="true"
              height="400px"
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th
                      v-for="item in headers"
                      :key="item.text"
                      :class="`text-${item.align}`"
                    >
                      {{ item.text }}
                    </th>
                  </tr>
                </thead>
                <TbodyPreRender
                  :is-loading="isLoading"
                  :cols="headers.length"
                  :items-length="items.length"
                  :enable-app-selector="false"
                />
                <tbody v-if="!isLoading">
                  <tr
                    v-for="item in items"
                    :key="`user-action-logs-tr-${item.idx}`"
                  >
                    <td class="text-left">
                      {{ item.created_at | dayjsLocalDatetime }}
                    </td>
                    <td class="text-left">
                      <v-chip
                        label
                        small
                        color="grey lighten-2"
                        class="my-1"
                        style="min-width: 86px;"
                      >
                        <v-icon
                          left
                          small
                        >
                          dns
                        </v-icon>
                        {{ item.ip }}
                      </v-chip>
                      <span class="caption pl-2">{{ item.country }}</span>
                      <br>
                      <span class="caption">{{ item.user_agent }}</span>
                    </td>
                    <td class="text-left">
                      <v-chip
                        label
                        small
                        color="grey lighten-1"
                        class="my-1"
                        style="width: 80px; cursor: pointer;"
                        @click="copyToClipboard(item.issued_access_token)"
                      >
                        <v-icon
                          left
                          small
                        >
                          content_copy
                        </v-icon>
                        Access
                      </v-chip>
                      <span class="caption pl-1">{{ item.issued_access_token | truncate(30) }}</span>
                      <br>
                      <v-chip
                        label
                        small
                        color="grey lighten-1"
                        class="my-1"
                        style="width: 80px; cursor: pointer;"
                        @click="copyToClipboard(item.issued_refresh_token)"
                      >
                        <v-icon
                          left
                          small
                        >
                          content_copy
                        </v-icon>
                        Refresh
                      </v-chip>
                      <span class="caption pl-1">{{ item.issued_refresh_token | truncate(30) }}</span>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="mb-5">
      <v-col cols="12">
        <v-pagination
          v-model="pagination.page"
          class="pagination-circle"
          circle
          :length="pagination.length"
          :total-visible="pagination.totalVisible"
          prev-icon="keyboard_arrow_left"
          next-icon="keyboard_arrow_right"
          @input="getItems(pagination.page)"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TbodyPreRender from '../_elements/table/TbodyPreRender'
import paginationLib from '../_elements/pagination/paginationLib'
import Api from './model/userApi'

export default {
  components: {
    TbodyPreRender
  },
  props: ['userIdx'],
  data() {
    return {
      isSetup: false,
      isLoading: false,
      logType: 'access_token',
      headers: [
        { text: 'Created At', align: 'left' },
        { text: 'IP / Location / Agent', align: 'left' },
        { text: 'Issued Token', align: 'left' }
      ],
      items: [],
      pagination: {
        page: 1
      }
    }
  },
  created() {
    this.getItems(this.pagination.page)
  },
  methods: {
    async getItems(page) {
      this.isLoading = true
      const res = await Api.getAccessLogs(this.userIdx, page)
      if (res.data.result === true) {
        this.items = res.data.data.items
        this.pagination = paginationLib.paginationMapper(res.data.data.pagination)
      }
      this.isLoading = false
    },
    async copyToClipboard(value) {
      const result = await this.$clipboard(value)
      if (result) {
        this.$bus.$emit('successMessage', '클립보드에 복사되었습니다')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.pagination-circle {
  ::v-deep .v-pagination__item,
  ::v-deep .v-pagination__navigation {
      outline: none;
  }
}
</style>
